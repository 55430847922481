import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { Country } from "@libs/enums/country.enum.ts";

interface ContactNameDto {
  title?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  nickName?: string;
}

export enum EntitlementType {
  Medicare = "MED",
  DVA = "DVA",
  HealthFund = "HF",
  NHI = "NHI",
  CSC = "CSC"
}

export enum UserQualificationType {
  MPL = "MPL",
  DAN = "DAN",
  UserIdentifiersNZ = "USERIDENTIFIERSNZ",
  ProviderContractsNZ = "PROVIDERCONTRACTSNZ",
  Physitrack = "PHYSITRACK",
  PrescriberNumber = "PRESCRIBERNUMBERAU"
}

export enum OrgUnitCompanyDataType {
  AuOrgUnitBusiness = "BUSINESSAU",
  AuOrgUnitIdentifier = "ORGUNITIDENTIFIERSAU",
  NzOrgUnitBusiness = "BUSINESSNZ",
  NzOrgUnitIdentifier = "ORGUNITIDENTIFIERSNZ",
  NzAccContracts = "NZACCCONTRACTS",
  Physitrack = "PHYSITRACK",
  DocumentSettings = "DOCUMENTSETTINGS",
  LocationData = "LOCATIONDATA"
}

export enum ProviderTypeText {
  alliedHealthService = "Allied health service",
  specifiedTreatmentProvider = "Specified treatment providers"
}

export enum ProviderTypeCode {
  alliedHealthService = "AHS",
  specifiedTreatmentProvider = "STP",
  orthotistService = "ORS"
}

export enum AccProviderTypeText {
  HandTherapistOT = "Hand therapist (OT)",
  HandTherapistPhysio = "Hand therapist (Physio)"
}

export enum AccProviderType {
  Physiotherapist = "81",
  OccupationalTherapist = "77",
  HandTherapist = "58",
  Acupuncturist = "79",
  Chiropractor = "86",
  Osteopath = "78",
  SpeechTherapist = "92",
  Podiatrist = "85",
  Orthotist = "60"
}

export enum PatientNoticeType {
  CheckAddress = "CA",
  Childprotection = "CP",
  CourtOrder = "CO",
  Declines = "DF",
  DeclinesCervicalScreens = "DC",
  DoctorShopper = "DS",
  DisabilityAccessRequired = "DA",
  DomesticViolence = "DV",
  FemaleProviderOnly = "FO",
  FirstnamePronounced = "FN",
  GenderDiverse = "GD",
  HearingImpaired = "HI",
  HistoryOfSeizureWhenTakingBP = "HS",
  MaleProviderOnly = "MP",
  NeedsLongAppointment = "AB",
  NightShiftWorker = "NS",
  NoEmailMobile = "NE",
  Other = "OT",
  PregnancyLoss = "SB",
  RecentHospitalDischarge = "RH",
  RepeatedDNAs = "RD",
  SurnamePronounced = "SP",
  SuspectedWhitecoatHypertension = "SW",
  VisionImpaired = "VI",
  HistoryOfAggression = "HA",
  JehovahsWitness = "JW",
  PleaseSee = "RT",
  SeniorProviderOnly = "SO"
}

export enum AccProviderContractTypeCodes {
  PhysiotherapistAHS = "81A",
  PhysiotherapistSTP = "81S",
  HandTherapistAHS = "58A",
  HandTherapisSTP = "58S",
  OccupationalTherapistAHS = "77A",
  OccupationalTherapistSTP = "77S",
  AcupuncturistSTP = "79S",
  ChiropractorSTP = "86S",
  OsteopathSTP = "78S",
  SpeechTherapistSTP = "92S",
  PodiatristAHS = "85A",
  PodiatristSTP = "85S",
  Orthotist = "60O"
}

export enum PatientNotePriority {
  Low = "LOW",
  Medium = "MED",
  High = "HIGH"
}

export enum EmploymentType {
  Employee = "EMP",
  SelfEmployed = "SEMP",
  CompanyOwner = "CO",
  CompanyPartOwner = "CPO",
  Volunteer = "VOL",
  Other = "OTH"
}

export interface EntitlementDto {
  patientEntitlementTypeCode?: string;
}

export enum PrefAndConsentPivotTabTypes {
  Appointments = "Appointments",
  Accounts = "Accounts",
  Forms = "Forms"
}

export interface MedicareDto extends EntitlementDto {
  number: string;
  irnNumber: string;
  expiryMonth?: number;
  expiryYear?: number;
}

export interface HealthFundDto extends EntitlementDto {
  fund: string;
  number: string;
  familyNumber?: string;
  expiry?: string;
}

export interface DvaDto extends EntitlementDto {
  number?: string;
  cardColor?: string;
  disability?: string;
}

export interface CscDto extends EntitlementDto {
  number: string;
  startDate?: string;
  expiry?: string;
}

export interface NhiDto extends EntitlementDto {
  number: string;
}

export interface ExternalProviderDto {
  contactId: string;
}

export interface InternalProviderDto {
  userId: string;
  lead: boolean;
}

export interface ContactResultItemDto {
  id: string;
  type: ContactType;
  value: ContactDto;
}

export type ContactDto =
  | IndividualContactDto
  | OrganisationContactDto
  | PatientDto;

export enum AddressType {
  Physical = "PHY",
  Postal = "POS",
  Both = "BOTH"
}

export interface AddressDto {
  city?: string;
  type: AddressType;
  street1?: string;
  street2?: string;
  suburb?: string;
  state?: string;
  postCode?: string;
  country?: Country;
}

export interface CommunicationDto {
  type: CommunicationType;
  value: string;
  preferred?: boolean;
}

export interface ContactBaseDto {
  id: string;
  type: ContactType;
  fullName?: ContactNameDto;
  organisationName?: string;
  jobTitle?: string;
  birthday?: string;
  communications?: CommunicationDto[];
  addresses?: AddressDto[];
  relationships?: RelationshipDto[];
  primaryAddressType?: string;
  commsRelationshipId?: string;
  isCommsSentToRelative?: boolean;
  disciplines?: string[];
  contactStatus?: ContactStatus;
  notes?: string;
  profilePictureUrl?: string;
  pronounSubjective?: string;
  pronounObjective?: string;
  pronounPossessive?: string;
  sex?: Sex;
  gender?: Gender;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface PatientDto extends ContactBaseDto {
  ethnicities?: string[];
  patientEntitlements?: EntitlementDto[];
  changeLog?: ChangeLogDto;
  commsRelationshipId?: string;
  isCommsSentToRelative?: boolean;
  externalProvider?: ExternalProviderDto[];
  interpreterLanguage?: string;
  internalProvider?: InternalProviderDto[];
  relationshipStatus?: string;
  type: ContactType.Patient;
  occupation?: string;
  causeOfDeath?: string;
  dateOfDeath?: string;
}

export interface IndividualContactDto extends ContactBaseDto {
  type: ContactType.Individual;
}

export interface OrganisationContactDto extends ContactBaseDto {
  type: ContactType.Organisation;
  organisationCategories?: string[];
  organisationRoles?: OrganisationRoleDto[];
  draftItemsEnabled: boolean;
}

export interface OrganisationRoleTypeCode {
  organisationRoleTypeCode: string;
}

export interface EmployerDto extends OrganisationRoleTypeCode {
  accreditedEmployer?: boolean;
  visitsPerClaim?: number;
  privateInsurerContactId?: string;
  claimsAdminEmail?: string;
  differentInvoicingEmail?: boolean;
  invoicingEmail?: string;
}

export interface HealthProviderDto extends OrganisationRoleTypeCode {}
export interface PublicInsurerDto extends OrganisationRoleTypeCode {}

export interface PrivateInsurerDto extends OrganisationRoleTypeCode {
  claimsAdminEmail?: string;
  accPrivateInsurer?: boolean;
  differentInvoicingEmail?: boolean;
  invoicingEmail?: string;
}

export type OrganisationRoleDto =
  | EmployerDto
  | PublicInsurerDto
  | PrivateInsurerDto
  | HealthProviderDto;

export interface AddPatientDto
  extends Omit<
    PatientDto,
    "id" | "eTag" | "type" | "changeLog" | "profilePictureUrl"
  > {}

export interface PatchPatientDto
  extends Patch<Omit<PatientDto, "changeLog" | "type" | "profilePictureUrl">> {
  id: string;
}

export interface AddIndividualContactDto
  extends Omit<
    IndividualContactDto,
    "id" | "eTag" | "type" | "changeLog" | "profilePictureUrl"
  > {}

export interface PatchIndividualDto
  extends Patch<
    Omit<IndividualContactDto, "changeLog" | "type" | "profilePictureUrl">
  > {
  id: string;
}

export interface AddOrganisationContactDto
  extends Omit<
    OrganisationContactDto,
    "id" | "eTag" | "type" | "changeLog" | "profilePictureUrl"
  > {}

export interface PatchOrganisationContactDto
  extends Patch<
    Omit<OrganisationContactDto, "changeLog" | "type" | "profilePictureUrl">
  > {
  id: string;
}
export interface PatientNoticePriorityDto extends RefDataDto<string> {}

export interface PatientNoticeTypeDto extends RefDataDto {
  text: string;
  isAdmin: boolean;
  isActive: boolean;
  isClinical: boolean;
  isTextMandatory: boolean;
}
export interface AddPatientNoticeDto {
  patientId: string;
  type: string;
  priority: string;
  isAdmin?: boolean;
  isClinical?: boolean;
  comment: string;
}
export interface PatientNoticeDto extends AddPatientNoticeDto {
  id: string;
  eTag: string;
}
export interface OrgUnitDto {
  id: string;
  eTag: string;
  profilePictureUrl?: string;
  orgUnitCompanyData: OrgUnitCompanyDataDto[];
}

export interface UpdateOrgUnitDto extends OrgUnitDto {}

export interface OrgUnitCompanyDataDto {
  orgUnitCompanyDataTypeCode: string;
}

export interface AuOrgUnitBusinessDto extends OrgUnitCompanyDataDto {}

export interface AuOrgUnitIdentifierDto extends OrgUnitCompanyDataDto {
  healthIdentifier: string;
}

export interface LocationDataDto extends OrgUnitCompanyDataDto {
  appointmentBookMarkerCode: string;
  nickName: string;
  defaultLocation?: boolean;
}

export interface OrgUnitEnabledPhysitrackDto extends OrgUnitCompanyDataDto {
  enabled: boolean | undefined;
}

export interface NzOrgUnitBusinessDto extends OrgUnitCompanyDataDto {
  nzbn: string;
}

export interface OrgUnitDocumentSettingsDto extends OrgUnitCompanyDataDto {
  usePracticeLetterhead: boolean;
}

export interface NzPracticeAccContractDto {
  accPracticeContractTypeCode: string;
  contractId: string;
  isSubscribed: boolean;
}

export interface NzPracticeAccContractCompanyData
  extends OrgUnitCompanyDataDto {
  contracts: NzPracticeAccContractDto[];
}

export interface NzOrgUnitIdentifierDto extends OrgUnitCompanyDataDto {
  hpiFacilityId?: string;
  hpiFacilityName?: string;
  hpiOrganisationId?: string;
  hpiOrganisationName?: string;
  defaultHpiCpn?: string;
  defaultHpiCpnUserId?: string;
}

export interface RelationshipDto {
  relatedContactId?: string;
  relationship: RelationshipType;
  hasRelationship?: boolean;
  metadata?: RelationshipMetadataDto;
}

export interface RelationshipMetadataDto {
  relationshipTypeCode?: string;
  careType?: string;
  isPrimary?: boolean;
  employmentType?: EmploymentType;
  otherInformation?: string;
  currentJob?: boolean;
  primaryJob?: boolean;
}

export interface RefPronounDto extends RefDataDto<PronounCodes> {
  subjective: string;
  objective: string;
  possessive: string;
}

export interface RefRelationshipTypeDto extends RefDataDto<RelationshipType> {
  reverseRelationshipTypeCode: string;
  isRole: boolean;
  isReverse: boolean;
  description: string;
}

export type GetContactsDto = PagingOptions & {
  ids?: string[];
  search?: string;
  types?: string[];
  categories?: string[];
  statuses?: string[];
  birthday?: string;
  phoneNumber?: string;
  organisationRoleTypeCodes?: string[];
  disciplines?: string[];
};

export type GetMatchedContactsDto = PagingOptions & {
  lastName?: string;
  types?: string[];
  statuses?: string[];
  birthday?: string;
  phoneNumber?: string;
};

export interface ProviderDto {
  id: string;
  eTag: string;
  changeLog?: ChangeLogDto;
  signatureUrl?: string;
  profilePhotoUrl?: string;
  userQualifications: UserQualificationsDto[];
}

export type PutProviderDto = ProviderDto;

export interface RefContactCategoryDto extends RefDataDto {
  categoryCode: number;
  contactType: ContactType;
}

export interface AddProviderDto extends Omit<ProviderDto, "eTag"> {}

export type UserQualificationsDto =
  | MedicareProviderLocationNumberDto
  | DisciplineAhpraNumberDto
  | CpnDto
  | AccContractListDto
  | PrescriberNumberDto;

export interface MedicareProviderLocationNumberDto {
  userQualificationTypeCode: UserQualificationType.MPL;
  orgUnitId?: string;
  medicareProviderNumber?: string;
}

export interface DisciplineAhpraNumberDto {
  userQualificationTypeCode: UserQualificationType.DAN;
  discipline?: string;
  ahpraNumber?: string;
}

export interface CpnDto {
  userQualificationTypeCode: UserQualificationType.UserIdentifiersNZ;
  cpn: string;
}

export interface AccContractListDto {
  userQualificationTypeCode: UserQualificationType.ProviderContractsNZ;
  accProviderContractTypeCodes: string[];
}

export interface PrescriberNumberDto {
  userQualificationTypeCode: UserQualificationType.PrescriberNumber;
  prescriberNumber: string;
}

export enum Sex {
  Male = "M",
  Female = "F",
  Other = "I", //stands for Intersex (cannot be changed)
  Unknown = "U"
}

export enum Gender {
  Male = "M",
  Female = "F",
  NonBinary = "N",
  GenderDiverse = "D",
  DifferentIdentity = "O"
}

export enum PronounCodes {
  HE = "HE",
  SHE = "SHE",
  THEY = "THEY"
}

export enum Pronoun {
  HE = "He",
  SHE = "She",
  HIM = "Him",
  HER = "Her",
  HIS = "His",
  HERS = "Hers",
  THEY = "They",
  THEM = "Them",
  THEIR = "Their"
}

export enum ContactType {
  Patient = "PAT",
  Individual = "PER",
  Organisation = "ORG"
}

export enum OrganisationCategory {
  ImagingProvider = "IMPR"
}

export enum DvaCardColour {
  White = "W",
  Gold = "G",
  Orange = "O"
}

export enum EmployerType {
  Current = "CU",
  Previous = "PR"
}

export enum EmployerGroup {
  Current = "current",
  Previous = "previous"
}

export enum RelationshipType {
  Carer = "CR",
  CarerOf = "CE",
  Child = "C",
  Parent = "P",
  EmergencyContact = "EC",
  EmergencyContactFor = "ECF",
  Employer = "ER",
  EmployeeOf = "EE",
  General = "GE",
  NextOfKin = "NK",
  NextOfKinFor = "NKF",
  PowerOfAttorney = "PA",
  PowerOfAttorneyFor = "PAF",
  Sibling = "SI",
  Stepparent = "ST",
  Stepchild = "SC",
  Grandparent = "GP",
  Grandchild = "GC",
  Spouse = "SP",
  Partner = "PT",
  ExtendedFamily = "EF",
  Friend = "FR",
  Guardian = "GD",
  GuardianFor = "GDF",
  PartOf = "OGP",
  Managing = "OGM",
  AccountHolder = "AH",
  AccountHolderFor = "AHF"
}

export enum ContactStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Deceased = "DECEASED",
  Merged = "MERGED"
}

export enum InteractionType {
  Encounter = "ENC",
  MedicationNewRx = "MEDNRX",
  MedicationCeasedRx = "MEDCRX",
  HistoryNewDiagnosisProcedure = "HISNDP",
  HistoryNewReaction = "HISNRE",
  NilKnownReaction = "NILRCN",
  DocumentCreated = "DOCC",
  ReferralOut = "DOCRO",
  Report = "DOCRP",
  DischargeSummary = "DOCDS",
  SpecialistLetter = "DOCSL",
  DocumentUploaded = "DOCUPL",
  Diagnosis = "NEWDGN",
  RequestCreated = "REQC"

  /* More interaction Types can be added as they are discovered */
}

export interface ProviderAccContractTypeDto extends RefDataDto {
  providerTypeCode: string;
  accPracticeContractTypeCode: string;
  providerTypeText: string;
  businessRoleCode: string;
}

export interface ProviderTypeDto extends RefDataDto {
  allowAcc45: boolean;
  referralOutOnly: boolean;
  userTypeCode: string;
}

export enum OrganisationRoleType {
  NzPublicInsurer = "NZPUBLICINSURER",
  NzEmployer = "NZEMPLOYER",
  NzPrivateInsurer = "NZPRIVATEINSURER",
  NzHealthProvider = "NZHEALTHPROVIDER",
  AuPublicInsurer = "AUPUBLICINSURER",
  AuEmployer = "AUEMPLOYER",
  AuPrivateInsurer = "AUPRIVATEINSURER",
  AuHealthProvider = "AUHEALTHPROVIDER"
}

export enum AustralianState {
  QLD = "QLD",
  NSW = "NSW",
  SA = "SA",
  TAS = "TAS",
  VIC = "VIC",
  WA = "WA",
  ACT = "ACT",
  NT = "NT"
}

export interface PatientNoticeArgs {
  patientIds?: string[];
}

export interface PatientMergeRequest {
  masterPatientId: string;
  duplicatePatientId: string;
}

export interface UpdateMergedStatusRequest {
  status: ContactStatus.Merged | ContactStatus.Active;
}

export enum CommunicationType {
  HomePhone = "HOME",
  WorkPhone = "WORK",
  Email = "EMAIL",
  Mobile = "MOBILE",
  AfterHours = "AFH",
  Fax = "FAX",
  Phone = "PHONE",
  Website = "WEBSITE"
}

export interface SaveProfilePictureDto {
  fileStagingId: string;
  entityId: string;
}

export interface SaveSignatureDto {
  fileStagingId: string;
  entityId: string;
}

export interface GetSasUriResponseDto {
  sasUri: string;
  fileStagingId: string;
}
