import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import {
  ClaimStatuses,
  ErrorMessageDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";

import {
  AddressDto,
  ContactType,
  OrgUnitCompanyDataType
} from "../practice/PracticeGateway.dtos.ts";

export interface AccScheduleItemDto {
  id: string;
  invoiceId: string;
  claimId: string;
  claimNumber?: string;
  claimStatus?: ClaimStatuses;
  accScheduleId?: string;
  invoiceItemId: string;
  paymentStatus?: string;
  errorMessages?: ErrorMessageDto[];
  statusReason?: string;
  paymentReason?: string;
}

export enum AccScheduleProcessingStatuses {
  Accepted = "ACCEPTED",
  Accredited = "ACCREDITED EMPLOYER",
  Declined = "DECLINE",
  Error = "ERROR",
  Held = "HELD",
  Incomplete = "INCOMPLETE",
  NotAvailable = "NOTAVAILABLE",
  NotVerified = "NOT VERIFIED",
  Pending = "PENDING",
  Private = "PRIVATE",
  Queued = "QUEUED",
  Ready = "READY",
  Discharged = "DISCHARGED",
  GetStatusError = "GETSTATUSERROR"
}

export enum AccScheduleTransactionStatuses {
  Queued = "QUEUED",
  Pending = "PENDING",
  Authorised = "AUTHORISED",
  AwaitingAuthorisation = "AWAITING AUTHORISATION",
  Invalid = "INVALID",
  CompleteAndAwaitingValidation = "COMPLETEANDAWAITINGVALIDATION",
  Paid = "PAID",
  Cancelled = "CANCELLED",
  PartiallyPaid = "PARTIALLY PAID",
  InException = "IN EXCEPTION",
  SentToAR = "SENT TO AR",
  Incomplete = "INCOMPLETE",
  Error = "ERROR",
  Ready = "READY",
  NotReady = "NOTREADY"
}

export interface AccScheduleDto {
  id: string;
  invoiceId: string;
  processingStatus: AccScheduleProcessingStatuses;
  items?: AccScheduleItemDto[];
  errorMessages?: ErrorMessageDto[];
  changedDate: string;
  submittedDate?: string;
  transactionStatus?: AccScheduleTransactionStatuses;
}

export interface InvoiceContact {
  contactType?: ContactType;
  name?: string;
  firstName?: string;
  lastName?: string;
  address?: AddressDto;
  phone?: string;
}

interface AddInvoiceSettingBusinessDto {
  businessType: OrgUnitCompanyDataType;
  abn?: string;
  gstNumber?: string;
  bankDetails?: AddBankAccountsDto;
}

export interface InvoiceSettingBusinessDto
  extends Omit<AddInvoiceSettingBusinessDto, "bankDetails"> {
  bankDetails?: BankAccountsDto;
  eTag: string;
}

export interface AddInvoiceSettingsDto {
  footer: string;
  alwaysApplyGst: boolean;
  gstPercent: number;
  business: AddInvoiceSettingBusinessDto;
}

export interface InvoiceSettingsDto
  extends Omit<AddInvoiceSettingsDto, "business"> {
  id: string; // invoiceSettingsId
  eTag: string;
  changeLog?: ChangeLogDto;
  business?: InvoiceSettingBusinessDto;
  statementFooter?: string;
  bankDetailsUnderStatementFooter?: boolean;
}

export interface UpdateInvoiceSettingDto
  extends Omit<InvoiceSettingsDto, "business" | "changeLog"> {
  business: AddInvoiceSettingBusinessDto;
}

export interface AddBankAccountsDto {
  accountName: string;
  bankName: string;
  bsb: string;
  accountNumber: string;
  locationId: string;
}

interface BankAccountsDto extends AddBankAccountsDto {
  id: string;
  invoiceSettingId: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export type AccountBalanceDto = {
  accountId: string;
  balance: number;
  totalOwing: number;
  unallocatedCredit: number;
};

export type DraftItemsTotalsDto = {
  accountId: string;
  total: number;
};

export type GetAccountBalanceDto = {
  accountId?: string;
  endDate?: string;
};

export enum FeeType {
  FlatRate = "FLATRATE",
  PerUnit = "PERUNIT",
  Km = "KM",
  Hourly = "HOURLY"
}

export enum ServiceRuleType {
  ACC = "ACC",
  Increment = "INCREMENT",
  UserDefinedAmount = "USERDEFINEDAMOUNT",
  PORequired = "POREQUIRED",
  Visit = "VISIT"
}

export enum BenefitSchedule {
  ACC = "ACC"
}

export interface ServiceRuleDto {
  ruleType: ServiceRuleType;
  max?: number;
  amount?: number;
  contractClass?: string;
}

export interface AddServiceInstanceDto {
  effectiveDate: string;
  fee?: number;
  isActive: boolean;
  rules: ServiceRuleDto[];
}

export enum GstMethod {
  calculateRounded = "CALCULATEROUNDED",
  included = "INCLUDED",
  notApplicable = "NOTAPPLICABLE"
}

export interface AddServiceDto {
  scheduleId: string;
  code: string;
  description: string;
  name: string;
  isService: boolean;
  gstMethod: GstMethod;
  feeType: FeeType;
  instances: AddServiceInstanceDto[];
}

export interface ServiceInstanceDto extends AddServiceInstanceDto {
  tax?: number;
}

export interface ServiceDto extends Omit<AddServiceDto, "instances"> {
  id: string;
  eTag: string;
  changeLog: ChangeLogDto;
  benefitId?: string;
  instances: ServiceInstanceDto[];
}

export interface UpdateServiceDto
  extends Omit<ServiceDto, "changeLog" | "instances"> {
  instances: Array<AddServiceInstanceDto>;
}

export interface ServiceFilterQuery {
  scheduleId?: string;
  effectiveDate?: string;
  code?: string;
  searchText?: string;
  isActive?: boolean;
  isService?: boolean;
  startDate?: string;
  endDate?: string;
}

export interface GetServiceDto extends PagingOptions, ServiceFilterQuery {
  hasBenefitId?: boolean;
}

export interface ScheduleDto extends AddScheduleDto {
  id: string;
  eTag: string;
  rootETag: string;
  changeLog?: ChangeLogDto;
  activeFees?: number;
  activeBenefitFees?: number;
  activeNonBenefitFees?: number;
  totalFees?: number;
  updatedByUserId?: string;
}

export interface AddScheduleDto {
  name: string;
  benefitSchedule?: BenefitSchedule;
  startDate: string;
  endDate?: string;
  description?: string;
}

export interface GetSchedulesArgsDto {
  scheduleIds?: string[];
  mustHaveActiveFee?: boolean;
  effectiveDate: string;
}

export type UpdateScheduleDto = Omit<ScheduleDto, "changeLog">;
// effectiveDate isn't technically required by the API, but without it then
//  multiple versions of the same service will be returned
export interface GetServiceSearchDto extends PagingOptions {
  effectiveDate: string;
  scheduleIds?: string[];
  serviceIds?: string[];
  searchText?: string;
  excludeDescription?: boolean;
}

export interface ServiceSearchDto {
  serviceId: string;
  scheduleId: string;
  code: string;
  name?: string;
  description?: string;
  fee?: number;
  gstMethod: GstMethod;
  feeType: FeeType;
  isService: boolean;
  rules?: ServiceRuleDto[];
}

export interface InvoiceEmailDto {
  invoiceId: string;
  email?: string;
}

export interface StatementDto extends AddStatementDto {
  id: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface AddStatementDto {
  accountContactId: string;
  startDate: string;
  endDate: string;
  date?: string;
}

export interface AddStatementBulkDto {
  startDate: string;
  endDate: string;
  date: string;
}

export interface GetStatementArgs extends PagingOptions {
  accountContactId?: string;
}

export interface StatementEmailDto {
  statementId: string;
  documentId: string;
  emailAddress: string;
}

export interface CancelTransactionRequest {
  reason: string;
  id: string;
}

export interface TransactionContactBaseDto {
  address?: AddressDto;
  phone?: string;
  billingEmail?: string;
}

export interface TransactionPatientDto extends TransactionContactBaseDto {
  contactType: ContactType.Patient;
  firstName: string;
  lastName: string;
}

export interface TransactionIndividualDto extends TransactionContactBaseDto {
  contactType: ContactType.Individual;
  firstName: string;
  lastName: string;
}

export interface TransactionOrganisationDto extends TransactionContactBaseDto {
  contactType: ContactType.Organisation;
  name: string;
}

export type TransactionAccountContactDto =
  | TransactionPatientDto
  | TransactionIndividualDto
  | TransactionOrganisationDto;

export interface InvoiceItemUser {
  firstName: string;
  lastName: string;
  title?: string;
}

export interface InvoiceItemId {
  invoiceItemId: string;
}

export type SpenderItem<T extends AllocationDto | RefundDto> = Omit<
  T["items"][0],
  "credit"
> & { transaction: T };

export interface Credit {
  allocated?: number;
  allocationStatus: string;
  spenders?: Array<SpenderItem<AllocationDto | RefundDto>>;
}

export interface AddCreditSpenderItem {
  creditId: string;
}

export interface CreditSpenderItem extends AddCreditSpenderItem {
  credit: Omit<CreditNoteDto | PaymentDto, "items">;
}

//Contains view information about an invoice item that the transaction item relates too.
export interface InvoiceItemView
  extends Omit<InvoiceItemDto, "eTag" | "changeLog" | "id"> {
  transaction: Omit<InvoiceDto, "items">;
}

/*
REFERENCE DTOS
These dtos contains information about the transactions that interact with them
for example invoice item will have information about the write-off that wrote it off.
and the write-off would have information about the invoice item it wrote off.
*/

//a transaction item that has a holds a reference to the invoice item it effects.
export interface InvoiceItemReferenceDto
  extends AddInvoiceItemReferenceDto,
    TransactionItemDto {
  invoiceItem: InvoiceItemView;
}

//reference to an item that interacts with a invoiceItem (e.g a write-off that has written-off some of that item,  or a credit-note that was created from it.)
export interface TransactionReferenceDto
  extends Omit<InvoiceItemReferenceDto, "invoiceItem"> {
  transaction: Omit<TransactionDto<InvoiceItemReferenceDto>, "items">;
}

//reference to a allocation item that has interacted with an invoiceItem. Contains the credit information of where the money came from.
export interface AllocationTransactionReferenceDto
  extends TransactionReferenceDto,
    CreditSpenderItem {}

export enum TransactionsOptions {
  invoice = "Invoice",
  payment = "Payment",
  statement = "Statement",
  writeOff = "Write-off",
  creditNote = "Credit note"
}

export enum BillingStatuses {
  cancelled = "CANCELLED",
  current = "CURRENT",
  adjusted = "ADJUSTED"
}

export enum PaymentStatuses {
  unpaid = "UNPAID",
  paid = "PAID",
  part = "PART",
  cancelled = "CANCELLED",
  overpaid = "OVERPAID"
}

export enum AllocationStatuses {
  Allocated = "ALLOCATED",
  PartAllocated = "PART",
  Unallocated = "UNALLOCATED"
}

export enum ItemType {
  Invoice = "INVOICE",
  Payment = "PAYMENT",
  Refund = "REFUND",
  CreditNote = "CREDITNOTE",
  WriteOff = "WRITEOFF",
  Allocation = "ALLOCATION"
}

/*
TRANSACTION ADD ITEMS
The item dtos for post
*/
export interface AddTransactionItemDto {
  itemType: ItemType;
  comment?: string;
  amount: number;
  locationId: string;
}

export interface AddInvoiceItemNewDto extends AddTransactionItemDto {
  id?: string;
  calendarEventId?: string;
  patientId: string;
  patient: TransactionPatientDto;
  userId: string;
  user: InvoiceItemUser;
  purchaseOrderNumber?: string;
  //service props
  serviceId: string;
  serviceDate: string;
  quantity: number;
  fee: number;
  gst: number;
  claimId?: string;
  accountId: string;
  episodeOfCareId?: string | null;
}

export enum PaymentMethod {
  Cash = "CASH",
  Cheque = "CHEQUE",
  DirectCredit = "DC",
  Eftpos = "EFTPOS",
  CreditCard = "CC"
}

export interface AddRefundItemDto
  extends AddCreditSpenderItem,
    AddTransactionItemDto {
  paymentMethod: PaymentMethod;
}

export interface AddInvoiceItemReferenceDto
  extends AddTransactionItemDto,
    InvoiceItemId {}

export interface AddAllocationItemDto
  extends AddInvoiceItemReferenceDto,
    AddCreditSpenderItem {}

export interface AddPaymentItemDto extends AddTransactionItemDto {
  paymentMethod: PaymentMethod;
}

/*
TRANSACTION ITEMS DTOS
From created items
*/

export interface UpsertInvoiceItemNewDto extends AddInvoiceItemNewDto {
  id?: string;
  eTag?: string;
}

export interface TransactionItemDto extends AddTransactionItemDto {
  id: string;
  status?: string;
  transactionId?: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface RefundItemDto
  extends AddRefundItemDto,
    TransactionItemDto,
    CreditSpenderItem {}

export interface AllocationItemDto
  extends AddAllocationItemDto,
    InvoiceItemReferenceDto,
    CreditSpenderItem {}

export interface PaymentItemDto extends AddPaymentItemDto, TransactionItemDto {}

export interface InvoiceItemDto
  extends Omit<AddInvoiceItemNewDto, "id">,
    TransactionItemDto {
  paid?: number;
  writtenOff?: number;
  credited?: number;
  paymentDate?: Date;
  paymentStatus?: PaymentStatuses;
  writeOffStatus?: string;
  service?: ServiceDto;
  references?: TransactionReferenceDto[] | AllocationTransactionReferenceDto[];
  //serviceProps
  feeType: FeeType;
  code?: string;
  description?: string;
  name?: string;
  scheduleId?: string;
  isService?: boolean;
  accScheduleItem?: AccScheduleItemDto;
}

//Add transaction DTOS
export interface AddNewTransactionDto<T extends AddTransactionItemDto> {
  accountId: string;
  location: string;
  transactionDate: string;
  accountContact: TransactionAccountContactDto;
  number: string;
  itemType: ItemType;
  comment?: string;
  status?: BillingStatuses;
  reference?: string;
  items: Array<T>;
}

export interface AddAllocationDto
  extends AddNewTransactionDto<AddAllocationItemDto> {}

export interface AddCreditNoteDto
  extends AddNewTransactionDto<AddInvoiceItemReferenceDto> {}

export interface AddRefundDto extends AddNewTransactionDto<AddRefundItemDto> {}

export interface AddWriteOffDto
  extends AddNewTransactionDto<AddInvoiceItemReferenceDto> {}

export interface AddPaymentDto
  extends AddNewTransactionDto<AddPaymentItemDto> {}

export interface AddInvoiceDto
  extends AddNewTransactionDto<AddInvoiceItemNewDto> {}

//transaction DTOS

export interface TransactionDto<T extends TransactionItemDto>
  extends AddNewTransactionDto<T> {
  id: string;
  status: BillingStatuses;
  adjustmentReason?: string;
  total: number;
  eTag: string;
  changedDate: string;
  changeLog?: ChangeLogDto;
}

export interface AllocationDto extends TransactionDto<AllocationItemDto> {}

export interface CreditNoteDto
  extends Credit,
    TransactionDto<InvoiceItemReferenceDto> {}

export interface RefundDto extends TransactionDto<RefundItemDto> {}

export interface WriteOffDto extends TransactionDto<InvoiceItemReferenceDto> {}

export interface PaymentDto extends TransactionDto<PaymentItemDto>, Credit {}

export interface InvoiceDto extends TransactionDto<InvoiceItemDto> {
  paid?: number;
  writtenOff?: number;
  sentDateTime?: string;
  paymentStatus: string;
  writeOffStatus?: string;
  credited: number;
  accSchedule?: AccScheduleDto;
}

export interface UpsertInvoiceItemNewRequest {
  items: UpsertInvoiceItemNewDto[];
  patientId?: string;
  calendarEventId?: string;
  accountId?: string;
}

export interface GetInvoiceItemsArgs extends PagingOptions {
  calendarEventIds?: string[];
  patientIds?: string[];
  itemIds?: string[];
  draftOnly?: boolean;
  accountIds?: string[];
  userIds?: string[];
}

export interface GetTransactionsArgs extends PagingOptions {
  itemTypes?: ItemType[] | ItemType;
  startTime?: string;
  endTime?: string;
  serviceId?: string;
  serviceStartTime?: string;
  serviceEndTime?: string;
  accChangedStartTime?: string;
  accChangedEndTime?: string;
  accountSearch?: string;
  patientSearch?: string;
  userSearch?: string;
  search?: string;
  billingStatuses?: BillingStatuses[] | BillingStatuses;
  paymentStatuses?: PaymentStatuses[] | PaymentStatuses;
  allocationStatuses?: string[];
  claimStatuses?:
    | AccScheduleProcessingStatuses[]
    | AccScheduleProcessingStatuses;
  transactionStatuses?:
    | AccScheduleTransactionStatuses[]
    | AccScheduleTransactionStatuses;
  accountIds?: string[] | string;
  excludedAccountIds?: string[] | string;
  transactionIds?: string[] | string;
  claimId?: string;
  userIds?: string[] | string;
  calendarEventIds?: string[] | string;
  sortColumn?: string;
  sortDescending?: boolean;
  number?: string;
  purchaseOrderNumber?: string;
  patientId?: string;
  creditId?: string;
  excludeAllocations?: boolean;
  numberSearch?: string;
}

export interface DocumentUrlDto {
  url: string;
}

export interface InvoiceDocumentPreviewData extends DocumentUrlDto {
  documentId: string;
  patientId: string;
  invoiceNumber: string;
}

type CommonGetBillingStoredDocumentArgs = {
  contactId: string;
};

type SwitchGetBillingStoredDocumentArgs<T> = T &
  (
    | {
        documentId: string;
        statementId?: never;
      }
    | {
        statementId: string;
        documentId?: never;
      }
  );

export type GetBillingStoredDocumentArgs =
  SwitchGetBillingStoredDocumentArgs<CommonGetBillingStoredDocumentArgs>;

type DocumentMetadataItemDto = {
  key: typeof METADATA_KEY_STATEMENT_ID | typeof METADATA_KEY_EXTENSION;
  value?: string;
};

export interface BillingStoredDocumentDto {
  metadata?: DocumentMetadataItemDto[];
  documentId?: string;
  contactId: string;
  eTag: string;
  date: string;
}

export type GetSingleBillingStoredDocumentArgs = Pick<
  BillingStoredInfo,
  "statementId"
> &
  CommonGetBillingStoredDocumentArgs;

export interface BillingStoredInfo {
  statementId: string;
  documentId: string;
}

export const METADATA_KEY_STATEMENT_ID = "statementId";
export const METADATA_KEY_EXTENSION = "Extension";

export enum InvoiceStatus {
  Paid = "PAID",
  Unpaid = "UNPAID",
  Cancelled = "CANCELLED",
  Adjusted = "ADJUSTED",
  Part = "PART",
  Owing = "OWING",
  Settled = "SETTLED",
  Draft = "DRAFT"
}

export enum BillType {
  Client = "C",
  Patient = "Patient"
  /* temporary add "Patient" - to be altered later on when dropdown list available */
}

export type TransactionBaseDto = TransactionDto<TransactionItemDto>;

export enum WriteOffStatus {
  fully = "FULLY",
  part = "PART",
  not = "NOT"
}

export enum WriteOffReason {
  EnteredInError = "Entered in error",
  InvoicePaid = "Invoice paid"
}
